import * as React from "react";
import { Link, navigate } from "gatsby";
import { Layout, Seo, DefaultButton, Card } from "../components";
import "../styles/screen.scss";
import { StaticImage } from "gatsby-plugin-image";
import AmericanStorageLogo from "../images/svg/American_Storage_Logo";
import {
  Container,
  Box,
  Grid,
  GridItem,
  VStack,
  Heading,
  Text,
  Flex,
} from "@chakra-ui/react";

const seoBundle = {
  title: "American Storage",
  subtitle: "Portable Storage Units for Rent",
  description: "Need more storage? We rent out protable storage units.",
  slug: "/",
};

const IndexPage = ({ location }) => {
  return (
    <Layout title={"Home"} location={location}>
      <Seo seoBundle={seoBundle} />
      <Box
        maxW='full'
        h='100%'
        // header takes up 40/50 pixels
        pt={["150px", null, "150px"]}
        px={["20px", null, "100px"]}
        pb={["60px", null, "100px"]}
        bg={"pink"}
      >
        <Grid
          templateColumns={["1fr", null, null, "repeat(3, 1fr)"]}
          templateRows={["1fr", null, null, "1fr"]}
          gap={6}
          maxW='1280px'
          mx='auto'
        >
          <GridItem colSpan={[1, null, null, 2]}>
            <Flex h='100%' align='center'>
              <Box>
                <VStack align={["center", null, null, "flex-start"]}>
                  <Box>
                    <Heading
                      as='h2'
                      color='dkBlue'
                      mb={["20px", null, null, "10px"]}
                      mt='0px'
                      fontWeight={800}
                      fontSize={["2em", "2em"]}
                      fontFamily='Lexend, sans-serif'
                      lineHeight={["100%", null, null, "120%"]}
                      textAlign={["center", null, null, "left"]}
                    >
                      Need More Closet Space?
                    </Heading>
                    <Heading
                      marginTop='0px'
                      as='h1'
                      color={["dkGrey", null, null, null, "dkBlue"]}
                      fontWeight={800}
                      fontSize={["3em", "4em"]}
                      lineHeight='100%'
                      mb='10px'
                      textAlign={["center", null, null, "left"]}
                    >
                      Order Portable On-site Storage
                    </Heading>
                    <Heading
                      marginTop='0px'
                      as='h3'
                      color={["dkGrey", null, null, null, "dkBlue"]}
                      fontWeight={800}
                      fontSize={["2em", "2em"]}
                      lineHeight='100%'
                      mb='10px'
                      textAlign={["center", null, null, "left"]}
                    >
                      (Store at Your Place or Ours)
                    </Heading>
                  </Box>
                  <Text
                    fontSize='2xl'
                    color='dkGrey'
                    maxW='500'
                    pb='0'
                    textAlign={["center", null, null, "left"]}
                  >
                    Take the hassle out of storage. It just takes one call for
                    us to deliver storage right to your home.
                  </Text>
                  <Text
                    fontSize='xl'
                    color='dkGrey'
                    maxW='500'
                    pb='10'
                    textAlign={["center", null, null, "left"]}
                  >
                    (Or we can store the container at our facility once it is
                    loaded.)
                  </Text>
                  <DefaultButton
                    onClick={() => navigate(`#how-it-works`)}
                    title='How It Works'
                  />
                  <Text
                    as='p'
                    color='dkGrey'
                    fontWeight='bold'
                    fontSize='5xl'
                    pt='35'
                    pb='0px'
                    mb='0px'
                    textAlign={["center", null, null, "left"]}
                  >
                    CALL TODAY
                  </Text>
                  <Box
                    as='p'
                    fontWeight='500'
                    fontSize='5xl'
                    fontFamily='Outfit, sans-serif'
                    lineHeight='120%'
                    pb='20'
                    textAlign={["center", null, null, "left"]}
                  >
                    <Link to={`tel:2085393383`}>(208) 539-3383</Link>
                  </Box>
                  <Box w={["100px", null, null, "200px"]}>
                    <AmericanStorageLogo />
                  </Box>
                </VStack>
              </Box>
            </Flex>
          </GridItem>
          <GridItem colSpan={1} display={["none", null, null, null, "block"]}>
            <Flex justify='flex-end' align='center' h='100%'>
              <StaticImage
                src='../images/box.png'
                alt='A Cardboard Box'
                placeholder='blurred'
                layout='fixed'
                width={650}
              />
            </Flex>
          </GridItem>
        </Grid>
      </Box>
      <div style={{ display: "grid" }}>
        {/* You can use a GatsbyImage component if the image is dynamic */}
        <StaticImage
          style={{
            gridArea: "1/1",
          }}
          layout='fullWidth'
          // You can optionally force an aspect ratio for the generated image
          // aspectRatio={3 / 1}
          // This is a presentational image, so the alt should be an empty string
          alt=''
          src='../images/boxwell.jpg'
          formats={["auto", "webp", "avif"]}
        />
        <div
          style={{
            // By using the same grid area for both, they are stacked on top of each other
            gridArea: "1/1",
            position: "relative",
            // This centers the other elements inside the hero component
            placeItems: "center",
            display: "grid",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          }}
        >
          <Container maxW='1280px' justify='center'>
            <Heading
              as='h2'
              color='#fff'
              fontWeight={800}
              fontSize={["2.5em", null, "3em", "4em"]}
              fontFamily='Lexend, sans-serif'
              lineHeight='120%'
              textAlign='center'
            >
              Your storage problems are over!
            </Heading>
            <Heading
              marginTop='10px'
              as='h1'
              color='#fff'
              fontWeight={800}
              fontSize={["1.5em", null, "2em", "3em"]}
              lineHeight='120%'
              textAlign='center'
            >
              Portable On-Site Storage to the Rescue
            </Heading>
          </Container>
        </div>
      </div>
      <Flex
        justify='center'
        align='center'
        bg='pink'
        p='80px 40px'
        flexDir='column'
        display={["flex"]}
      >
        <Heading
          marginTop='0px'
          as='h1'
          color='dkBlue'
          fontWeight={800}
          fontSize={["1.5em", "3em"]}
          lineHeight='120%'
          mb='40px'
          textAlign='center'
          maxW={800}
        >
          We have 3 types of storage solutions
        </Heading>
        {/* <StaticImage
          src='../images/chart.png'
          alt='chart of features'
          placeholder='blurred'
          layout='constrained'
          width={800}
        /> */}
        <Text fontSize='2xl'>
          <ol>
            <li>
              <Text
                fontFamily='Outfit, sans-serif'
                fontSize={["2em", "2em"]}
                fontWeight='bold'
                color='dkBlue'
              >
                Rent a unit at our facility
              </Text>
            </li>
            <li>
              {" "}
              <Text
                fontFamily='Outfit, sans-serif'
                fontSize={["2em", "2em"]}
                fontWeight='bold'
                color='dkBlue'
              >
                Schedule a portable unit for delivery to your home.
              </Text>
            </li>
            <li>
              {" "}
              <Text
                fontFamily='Outfit, sans-serif'
                fontSize={["2em", "2em"]}
                fontWeight='bold'
                color='dkBlue'
              >
                Schedule a portable unit for delivery to your home, load it,
                then have us store it at our secure facility.
              </Text>
            </li>
          </ol>
        </Text>
      </Flex>
      <Flex
        justify='center'
        align='center'
        bg='linear-gradient(180deg, #FFFFFF 0%, rgba(255, 245, 238, 1) 100%);'
        flexDir='column'
      >
        <Grid
          templateColumns={["1fr", null, null, "repeat(2, 1fr)"]}
          templateRows={["repeat(2, 1fr)", null, null, "1fr"]}
          gap={6}
          py={["40px", null, null, "80px"]}
          maxW='1280px'
          mx='auto'
        >
          <GridItem colSpan={1} mt={[0, null, null, "70px"]}>
            <Flex justify='center' p='40px' align='center' h='100%'>
              <StaticImage
                src='../images/storage-bin.png'
                alt='portable metal storage container'
                placeholder='blurred'
                layout='constrained'
                width={700}
              />
            </Flex>
          </GridItem>
          <GridItem px='20px' colSpan={1} id='how-it-works'>
            <Flex justify='center' align='center' h='100%'>
              <Card>
                <Text
                  fontFamily='Outfit, sans-serif'
                  fontSize={["2em", "3em"]}
                  fontWeight='bold'
                  color='dkBlue'
                  mb='20px'
                >
                  How It Works
                </Text>
                <Flex
                  mb='20px'
                  flexDirection='row'
                  justify='flex-start'
                  align='center'
                >
                  <Box mr='10px'>
                    <StaticImage
                      src='../images/phone.png'
                      alt='telephone'
                      placeholder='blurred'
                      layout='constrained'
                      width={30}
                    />
                  </Box>
                  <Flex justify='center'>
                    <Text
                      fontFamily='Outfit, sans-serif'
                      fontSize={["0.5em", "1em"]}
                      fontWeight='bold'
                      color='dkBlue'
                      textAlign='left'
                    >
                      Call us at (208) 539-3383
                    </Text>
                  </Flex>
                </Flex>
                <Flex
                  mb='20px'
                  flexDirection='row'
                  justify='flex-start'
                  align='center'
                >
                  <Box mr='10px'>
                    <StaticImage
                      src='../images/calendar.png'
                      alt='calendar'
                      placeholder='blurred'
                      layout='constrained'
                      width={30}
                    />
                  </Box>
                  <Flex justify='center'>
                    <Text
                      fontFamily='Outfit, sans-serif'
                      fontSize={["0.5em", "1em"]}
                      fontWeight='bold'
                      color='dkBlue'
                      textAlign='left'
                    >
                      Schedule your storage container for when you will need it
                    </Text>
                  </Flex>
                </Flex>
                <Flex
                  mb='40px'
                  flexDirection='row'
                  justify='flex-start'
                  align='center'
                >
                  <Box mr='10px'>
                    <StaticImage
                      src='../images/house.png'
                      alt='house'
                      placeholder='blurred'
                      layout='constrained'
                      width={30}
                    />
                  </Box>
                  <Flex justify='center'>
                    <Text
                      fontFamily='Outfit, sans-serif'
                      fontSize={["0.5em", "1em"]}
                      fontWeight='bold'
                      color='dkBlue'
                      textAlign='left'
                    >
                      We will deliver it, hassle-free, to your home
                      <br /> (or we can store the container at our facility,
                      too!)
                    </Text>
                  </Flex>
                </Flex>
                <DefaultButton
                  onClick={() => {
                    navigate("/pricing-page");
                  }}
                  title='See Unit Sizes'
                />
              </Card>
            </Flex>
          </GridItem>
        </Grid>
      </Flex>
      <Flex
        justify='center'
        align='center'
        bg='pink'
        p='80px 40px'
        flexDir='column'
        display={["none", null, null, "flex"]}
      >
        <Heading
          marginTop='0px'
          as='h1'
          color='dkBlue'
          fontWeight={800}
          fontSize={["1.5em", "3em"]}
          lineHeight='120%'
          mb='40px'
          textAlign='center'
          maxW={800}
        >
          Why use American Storage over other national competitors?
        </Heading>
        <StaticImage
          src='../images/chart.png'
          alt='chart of features'
          placeholder='blurred'
          layout='constrained'
          width={800}
        />
        <div style={{ visibility: "hidden", fontSize: "8px" }}>
          <ul>
            <li>Fully galvanized seamless painted steel</li>
            <li>Locally owned and operated</li>
            <li>Optional placement - your place or ours</li>
            <li>Same day deliveries</li>
            <li>Weather and rodent proof</li>
          </ul>
        </div>
      </Flex>
    </Layout>
  );
};

export default IndexPage;
